<template>
  <div class="my_focus_on">
    <div class="list">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onList">
        <div class="item" v-for="item in followList" :key="item.id">
          <div class="card" v-if="item.doctorSource ==2" @click="onDetail(item)">
            <div class="icon">
              <img :src="item.doctorImage" alt="">
            </div>
            <div class="info">
              <div class="txt1"><span>{{item.doctorName}}</span> {{item.doctorClinic}} {{item.doctorProfessionalTitle}}</div>
              <div class="txt2">{{item.doctorHospitalName}}</div>
              <div class="txt3">擅长：{{item.adeptTerritory}}</div>
              <div class="txt4">{{item.buyPeopleCount}}人已选择购买</div>
            </div>
            <div class="remark">
              <!-- <div class="money">¥<span>99</span>/次</div> -->
            </div>
          </div>
          <div class="card" v-if="item.doctorSource ==1" @click="onDetail(item)">
            <div class="icon">
              <img :src="item.doctorHeadImg" alt="">
            </div>
            <div class="info">
              <div class="txt1"><span>{{item.doctorName}}</span> {{item.doctorClinic}} {{item.doctorProfessionalTitle}}</div>
              <div class="txt2">{{item.doctorHospitalName}}</div>
              <div class="txt3">擅长：{{item.adeptTerritory}}</div>
              <div class="txt4">100+人已选择购买</div>
            </div>
            <div class="remark">
              <!-- <div class="money">¥<span>99</span>/次</div> -->
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      pageNum: 1,
      docPage: 1,
      pageSize: 10,
      attentionType: 1,
      followList: [],
      loading: false,
      finished: false
    }
  },
  created() {
    // this.onList();
  },
  methods: {
    onList() {
      this.loading = true;
      let userData = JSON.parse(localStorage.getItem('userData')) || '';
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        attentionType: this.attentionType,
        memberId: userData.memberId
      }
      this.$http('get', '/server/memberAttention/attentionInfoList', params, 1).then(res => {
        // console.log(res);
        this.pageNum += 1;
        if (res.data.data) {
          this.followList = this.followList.concat(res.data.data);
          this.loading = false;
        } else {
          // this.pageNum = 1;
          this.attentionType = 2
          params.pageNum = this.docPage;
          params.attentionType = this.attentionType;
          this.$http('get', '/server/memberAttention/attentionInfoList', params, 1).then(res => {
            this.docPage += 1;
            if (res.data.data) {
              this.followList = this.followList.concat(res.data.data);
              this.loading = false;
            } else {
              this.finished = true;
            }

          })
        }
      })

    },
    // 进入医生详情
    onDetail(item) {
      if (item.doctorSource == 2) { // 1：药极客 2：春雨 isClick
        this.$router.push({ path: '/doctor_detail', query: { docType: item.doctorSource, doctor_id: item.doctorId, dataId: item.id } })
      } else {
        this.$router.push({ path: '/doctor_detail', query: { docType: item.doctorSource, doctor_id: item.doctorId, isClick: true } })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.my_focus_on {
  min-height: calc(100% - 0.2rem);
  padding-top: 0.2rem;
  background: #f8f8f8;
  .item {
    margin: 0 0.22rem 0.15rem;
    padding: 0.24rem;
    background: #fff;
    border-radius: 8px;
    .footer_btn {
      display: flex;
      justify-content: flex-end;
      button {
        padding: 0.1rem 0.2rem;
        border: 0;
        background: #ffc025;
        color: #fff;
        border-radius: 4px;
      }
      button:nth-child(1) {
        margin-right: 0.2rem;
      }
    }
    .card {
      position: relative;
      display: flex;
      display: -webkit-flex;
      align-items: flex-start;
      -webkit-align-items: flex-start;

      .icon {
        margin-right: 0.22rem;
        width: 1.4rem;
        height: 1.4rem;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .info {
        flex: 1;
        -webkit-flex: 1;
        div {
          margin-bottom: 0.16rem;
        }
        .txt1 {
          font-size: 0.26rem;
          color: #333333;
          line-height: 0.3rem;
          span {
            font-size: 0.3rem;
            font-weight: bold;
          }
        }
        .txt2 {
          font-size: 0.24rem;
          line-height: 0.24rem;
          color: #666666;
        }
        .txt3 {
          font-size: 0.2rem;
          line-height: 0.28rem;
          color: #999999;
          width: 3.9rem;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          word-wrap: break-word;
          white-space: normal !important;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .txt4 {
          font-size: 0.22rem;
          line-height: 0.22rem;
          color: #666666;
          span {
            color: #ffc025;
          }
        }
        .sys_txt1 {
          font-size: 0.28rem;
          line-height: 0.28rem;
          color: #333333;
        }
        .sys_txt2 {
          font-size: 0.24rem;
          line-height: 0.24rem;
          color: #666666;
        }
        .sys_txt3 {
          font-size: 0.2rem;
          line-height: 0.2rem;
          color: #ffc025;
        }
      }
      .remark {
        .money {
          color: #ffc025;
          font-size: 0.2rem;
          text-align: center;
          span {
            font-size: 0.24rem;
            font-weight: bold;
          }
        }
      }
    }
  }
}
</style>
